<template>
  <div>
    <p class="text-body-2">Selecione as <b>Carteiras</b> que deseja receber notificações diária</p>
    <v-flex>
      <v-autocomplete
        v-model="model"
        :items="items"
        :search-input.sync="search"
        hide-no-data
        hide-selected
        item-text="shortName"
        placeholder="Procurar carteiras"
        return-object
        :loading="isLoading"
      >
        <template v-slot:append-outer>
          <v-btn @click="addFund(model)" icon x-small color="green">
            <v-icon size="26">mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-autocomplete>
    </v-flex>
    <v-divider v-if="items.length > 0"></v-divider>
    <v-data-table
      :items="notificationsSub"
      :headers="headers"
      :items-per-page="50"
      hide-default-footer
      hide-default-header
      :mobile-breakpoint="0"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th>Carteira</th>
            <th class="text-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" size="25">mdi-email-check-outline</v-icon>
                </template>
                <span>E-mail</span>
              </v-tooltip>
            </th>
            <th class="text-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" size="22">mdi-cellphone-sound</v-icon>
                </template>
                <span>Mobile - Em breve</span>
              </v-tooltip>
            </th>
            <th class="text-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" size="22">mdi-trash-can-outline</v-icon>
                </template>
                <span>Remover</span>
              </v-tooltip>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item.notificationDescription="{ item }">
        {{ item.notificationDescription }}
      </template>
      <template v-slot:item.email="{ item }">
        <div class="justify-center d-flex">
          <v-checkbox v-on:change="update(item, item.enabled)" v-model="item.enabled"></v-checkbox>
        </div>
      </template>
      <template v-slot:item.mobile>
        <div class="justify-center d-flex">
          <v-checkbox disabled></v-checkbox>
        </div>
      </template>
      <template v-slot:item.remove="{ item }">
        <div class="text-center">
          <v-icon @click="deleteNotification(item)" size="22" color="red">mdi-trash-can-outline</v-icon>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  props: {
    notifications: Array,
  },
  data: () => ({
    returnBoard: null,
    descriptionLimit: 60,
    items: [],
    notificationsSub: [],
    isLoading: false,
    model: null,
    search: null,
    headers: [
      { text: 'Fundo', value: 'notificationDescription' },
      { text: 'Email', value: 'email' },
      { text: 'Mobile', value: 'mobile' },
      { text: 'Remover', value: 'remove', align: 'center' },
    ],
  }),
  watch: {
    async search(val) {
      if (!val || val.length < 2) return;

      this.isLoading = true;
      const {
        data: { funds },
      } = await api.funds.searchv2({ query: val }, { params: { limit: 10, loyall: true } });
      this.items = funds;
      this.isLoading = false;
    },
    notifications(val) {
      this.notificationsSub = [...val].filter((value) => value.notificationType === 'wallet_history');
      this.$emit('notifications-updated', val);
    },
  },
  mounted() {
    this.notificationsSub = [...this.notifications].filter(
      (value) => value.notificationType === 'wallet_history',
    );
  },
  methods: {
    async addFund(val) {
      const {
        data: {
          user: {
            notifications: { subscribed },
          },
        },
      } = await api.me.putNotification({
        notification: {
          notificationType: 'wallet_history',
          notificationKey: val._id,
          notificationDescription: val.shortName || val.name,
          enabled: true,
        },
      });

      this.$emit('notifications-updated', subscribed);
    },

    async update(item, enabled) {
      const {
        data: {
          user: {
            notifications: { subscribed },
          },
        },
      } = await api.me.putNotification({
        notification: {
          notificationType: 'wallet_history',
          notificationKey: item.notificationKey,
          notificationDescription: item.notificationDescription,
          enabled,
        },
      });

      this.$emit('notifications-updated', subscribed);
    },

    async deleteNotification(val) {
      const {
        data: {
          user: {
            notifications: { subscribed },
          },
        },
      } = await api.me.deleteNotification({
        data: {
          notification: {
            notificationType: 'wallet_history',
            notificationKey: val.notificationKey,
            notificationDescription: val.shortName || val.name,
            enabled: true,
          },
        },
      });

      this.$emit('notifications-updated', subscribed);
    },
  },
};
</script>
