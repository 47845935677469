<template>
  <div>
  <v-simple-table
    :mobile-breakpoint="0"
    dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Notificação</th>
            <th class="text-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" size="25">mdi-email-check-outline</v-icon>
                </template>
                <span>E-mail</span>
              </v-tooltip>
            </th>
            <th class="text-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" size="22">mdi-cellphone-sound</v-icon>
                </template>
                <span>Mobile - Em breve</span>
              </v-tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(defaultNotification, index) in defaultNotifications" :key="index">
            <td>{{defaultNotification.notificationDescription}}</td>
            <td>
              <div class="justify-center d-flex">
                <v-checkbox v-on:change="update(defaultNotification, defaultNotification.enabled)" v-model="defaultNotification.enabled"></v-checkbox>
              </div>
            </td>
            <td>
              <div class="justify-center d-flex">
                <v-checkbox disabled></v-checkbox>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
  </v-simple-table>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  props: {
    notifications: Array,
    subscribed: Array,
  },
  data: (() => ({
    tooltipShow: false,
    defaultNotifications: {
      returnBoard: {
        notificationType: 'return_board',
        notificationKey: 'return_board',
        notificationDescription: 'Quadro de Retornos',
        enabled: false,
      },
    },
    subscribedNotifications: [],
  })),
  mounted() {
    this.notifications.forEach((notification) => {
      if (this.defaultNotifications[notification.notificationType]) {
        this.defaultNotifications[notification.notificationType] = notification;
      }
    });
  },
  watch: {
    notifications() {
      this.notifications.forEach((notification) => {
        if (this.defaultNotifications[notification.notificationType]) {
          this.defaultNotifications[notification.notificationType] = notification;
        }
      });
    },
  },
  methods: {
    async update(item, enabled) {
      const { data: { user: { notifications: { subscribed } } } } = await api.me.putNotification({
        notification: {
          notificationType: item.notificationType,
          notificationKey: item.notificationKey,
          notificationDescription: item.notificationDescription,
          enabled,
        },
      });

      this.$emit('notifications-updated', subscribed);
    },
  },
};
</script>
