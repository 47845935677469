var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-flex',[_c('v-autocomplete',{attrs:{"items":_vm.items,"search-input":_vm.search,"hide-no-data":"","hide-selected":"","item-text":"shortName","placeholder":"Procurar fundos","return-object":"","loading":_vm.isLoading},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":"","x-small":"","color":"green"},on:{"click":function($event){return _vm.addFund(_vm.model)}}},[_c('v-icon',{attrs:{"size":"26"}},[_vm._v("mdi-plus")])],1)]},proxy:true}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),(_vm.items.length > 0)?_c('v-divider'):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.notificationsSub,"headers":_vm.headers,"items-per-page":50,"hide-default-footer":"","hide-default-header":"","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Carteira")]),_c('th',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"25"}},on),[_vm._v("mdi-email-check-outline")])]}}])},[_c('span',[_vm._v("E-mail")])])],1),_c('th',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"22"}},on),[_vm._v("mdi-cellphone-sound")])]}}])},[_c('span',[_vm._v("Mobile - Em breve")])])],1),_c('th',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"22"}},on),[_vm._v("mdi-trash-can-outline")])]}}])},[_c('span',[_vm._v("Remover")])])],1)])])]},proxy:true},{key:"item.notificationDescription",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.notificationDescription)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"justify-center d-flex"},[_c('v-checkbox',{on:{"change":function($event){return _vm.update(item, item.enabled)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1)]}},{key:"item.mobile",fn:function(){return [_c('div',{staticClass:"justify-center d-flex"},[_c('v-checkbox',{attrs:{"disabled":""}})],1)]},proxy:true},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"22","color":"red"},on:{"click":function($event){return _vm.deleteNotification(item)}}},[_vm._v("mdi-trash-can-outline")])],1)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-body-2"},[_vm._v("Selecione os "),_c('b',[_vm._v("Fundos")]),_vm._v(" que deseja receber notificações diária")])}]

export { render, staticRenderFns }