<template>
  <v-container grid-list-md>
    <!-- <h1 class="text-h5">Detalhes do usuário</h1> -->
    <v-row>
      <v-col class="col-12 offset-0 col-md-8 offset-md-2 col-lg-4 offset-lg-4">
        <v-card class="profile-card">
          <v-card-title class="text-center">
            <v-avatar class="profile elevation-2" color="grey" size="164">
              <v-img
                :src="`${user.avatar ? user.avatar.replace('s50', 's150') : ''}`"
              ></v-img>
            </v-avatar>
          </v-card-title>
          <v-card-text class="text-center">
            <h1 class="text-h6">{{user.name}}</h1>
            <h2 class="text-body-1 mb-2">{{user.email}}</h2>
            <user-role-chip :role="user.acl" small />
            <p class="mt-3 mb-1">
              <b>Último login:</b>
              {{Moment(user.lastLogin).format('DD/MM/YYYY HH:mm')}}
            </p>
            <p class="mb-0">
              <b>Criado em:</b>
              {{Moment(user.createdAt).format('DD/MM/YYYY')}}
            </p>
          </v-card-text>
        </v-card>
        <h1 class="text-subtitle-1 mt-4">Notificações</h1>
        <v-card class="mt-3">
            <v-tabs
              v-model="tab"
              background-color="primary"
              dark
              grow
            >
            <v-tabs-slider color="blue"></v-tabs-slider>
             <v-tab href="#tab-1">
                Gerais
              </v-tab>
              <v-tab href="#tab-2">
                Carteiras
              </v-tab>
              <v-tab href="#tab-3">
                Fundos
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item :value="'tab-1'" >
                <v-card flat>
                  <v-card-text>
                    <GeneralNotifications
                      v-on:notifications-updated="(val) => { user.notifications.subscribed = val }"
                      :notifications="user.notifications.subscribed"
                    />
                  </v-card-text>
                </v-card>
            </v-tab-item>
            <!-- Notificação Carteiras -->
             <v-tab-item :value="'tab-2'" >
                <v-card flat>
                  <v-card-text>
                    <WalletsNotifications
                      v-on:notifications-updated="(val) => { user.notifications.subscribed = val }"
                      :notifications="user.notifications.subscribed"
                    />
                  </v-card-text>
                </v-card>
            </v-tab-item>
            <!-- Notificação Fundos -->
            <v-tab-item :value="'tab-3'" >
                <v-card flat>
                  <v-card-text>
                    <FundsNotifications
                      v-on:notifications-updated="(val) => { user.notifications.subscribed = val }"
                      :notifications="user.notifications.subscribed"
                    />
                  </v-card-text>
                </v-card>
            </v-tab-item>
           </v-tabs-items>
        </v-card>
         <v-divider class="my-4"></v-divider>
         <v-card class="mt-3">
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="tokens"
            :items-per-page="20"
            class="elevation-1 mt-3"
            expand-icon="mdi-plus-circle"
            :group-desc="true"
            sort-by="nextRunAt"
            :sort-desc="true"
            :mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-toolbar dense flat color="white" class="pt-2">
                <v-toolbar-title class="text-body-2">Tokens de integração</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" dark class="mb-2" @click="newTokenDialog = true" small>Gerar token</v-btn>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <span class="text-no-wrap">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text icon x-small v-clipboard:copy="item.token">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copiar Token</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="revokeTokenDialog = true; revokeTokenId = item._id"
                    text icon v-on="on" color="red" x-small>
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </template>
                  <span>Cancelar Token</span>
                </v-tooltip>
              </span>
            </template>
            <template
              v-slot:item.createdAt="{ item }"
            >{{Moment(item.createdAt).format('DD/MM/YYYY')}}</template>
            <template v-slot:item.revokedBy="{ item }">
              <span class="text-no-wrap">{{item.revokedBy ? item.revokedBy.name : '---'}}</span>
            </template>
            <template v-slot:item.active="{ item }">
              <div class="state-col">
                <v-chip class="ma-2" color="green" text-color="white" v-if="item.active" small>Ativo</v-chip>
                <v-chip v-else class="ma-2" color="red" text-color="white" small>Bloqueado</v-chip>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- New Token Dialog -->
    <v-dialog v-model="newTokenDialog" width="300">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2" primary-title>Gerar Token</v-card-title>
        <v-card-actions>
            <v-btn color="success" text @click="generateNewToken">Gerar</v-btn>
            <div class="flex-grow-1"></div>
            <v-btn color="red" text @click="newTokenDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Revoke Token Dialog -->
    <v-dialog v-model="revokeTokenDialog" width="300">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2" primary-title>Cancelar Token</v-card-title>
        <v-card-actions>
            <v-btn color="red" dark text @click="revokeToken">Cancelar</v-btn>
            <div class="flex-grow-1"></div>
            <v-btn text @click="revokeTokenDialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.profile-card {
  margin-top: 90px;
}
.profile {
  margin: auto;
  margin-top: -90px;
}
.state-col .v-chip {
  width: 90px;
  text-align: center;
  display: inline-block;
}
</style>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';

import UserRoleChip from '@/components/global/UserRoleChip.vue';

// Notifications
import GeneralNotifications from './notifications/General.vue';
import WalletsNotifications from './notifications/Wallets.vue';
import FundsNotifications from './notifications/Funds.vue';

export default {
  components: {
    UserRoleChip,
    GeneralNotifications,
    WalletsNotifications,
    FundsNotifications,
  },

  data: () => ({
    loading: true,
    Moment: moment,
    overview: {},
    user: {
      notifications: {
        subscribed: [],
        history: [],
      },
    },
    tab: null,
    tokens: [],
    newTokenDialog: false,
    revokeTokenDialog: false,
    revokeTokenId: '',
    returnBoard: false,
    headers: [
      { text: 'Ações', value: 'actions' },
      { text: 'Token Id', value: 'uid' },
      { text: 'Criado em', value: 'createdAt' },
      //   { text: 'Token', value: 'token' },
      { text: 'Estado', value: 'active' },
      { text: 'Bloqueado por', value: 'revokedBy' },
    ],
  }),

  async mounted() {
    await this.getData();
  },

  methods: {
    moment,
    async getData() {
      try {
        this.loading = true;
        const { data } = await api.me.whoami();
        const { data: dataTokens } = await api.me.getIntegrationTokens();
        this.user = data;
        this.tokens = dataTokens.tokens;
        this.loading = false;
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response
            ? error.response.data.error.message
            : error.message,
          color: 'error',
          status: true,
        });
      }
    },

    async generateNewToken() {
      try {
        this.loading = true;
        await api.me.generateIntegrationToken(
          this.user._id,
        );
        this.loading = false;
        this.newTokenDialog = false;
        await this.getData();
      } catch (error) {
        // as
      }
    },

    async revokeToken() {
      try {
        this.loading = true;
        const { revokeTokenId } = this;
        await api.me.revokeUserIntegrationToken(
          this.user._id,
          revokeTokenId,
        );
        this.loading = false;
        this.revokeTokenDialog = false;
        await this.getData();
      } catch (error) {
        // as
      }
    },
  },
};
</script>
